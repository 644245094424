import axios from "axios";
import { baseURL } from "../../../../config";

// export const DASHBOARD_URL = "api/products";
export const DASHBOARD_URL = `${baseURL}/control/dashboard?_limit=5&_sort=created_at:DESC`;

 
// READ
export function getMainDashboard() {
  return axios.get(DASHBOARD_URL);
}
 

 
 