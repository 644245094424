import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"; 
// import * as providers from "../../Providers";

function ProviderSpecialtyInformation(props) {
  // Fields
  const [loading, setloading] = useState(false);
  const [pic, setPic] = useState("");
  const dispatch = useDispatch();
  const provider = useSelector((state) => state.providers.provider, shallowEqual);

  useEffect(() => {
  
    if (provider?.user?.profile_picture) {
      setPic(provider?.user?.profile_picture?.url);
    }
  }, [provider]);
  // Methods

  // UI Helpers
  const initialValues = {
    title: provider?.title,
    hospital: provider?.hospital,
    relevant_board_number: provider?.relevant_board_number,
    biography: provider?.biography,
    reference_email:provider?.reference_email,
    reference_phone:provider?.reference_phone,
    status:provider?.status,
    city:provider?.city,
    state:provider?.state,
    medical_school:provider?.medical_school,
    school_completion_date:provider?.school_completion_date,
    country: provider?.country,
    official_id: provider?.official_id,
    supporting_documents: provider?.supporting_documents,
    rate: provider?.rate,
    pay_provider: provider?.pay_provider,
    pay_number: provider?.pay_number,
    city_state_country: `${provider?.city}/${provider?.state}/${provider?.country}`
  };
  const Schema = Yup.object().shape({
    pic: Yup.string(),
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    companyName: Yup.string(),
    phone: Yup.string().required("Phone is required"),
    email: Yup.string()
      .email("Wrong email format")
      .required("Email is required"),
    website: Yup.string(),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
    //   saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  const getUserPic = () => {
    if (!pic) {
      return "none";
    }

    return `url(${pic})`;
  };
  const removePic = () => {
    setPic("");
  };
  return (
    <form
      className="card card-custom card-stretch"
      onSubmit={formik.handleSubmit}
    >
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Specialty Information
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Information set as a provider
          </span>
        </div>
        {/* <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          <Link
            to="/user-profile/profile-overview"
            className="btn btn-secondary"
          >
            Cancel
          </Link>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        {/* begin::Body */}
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">General Info</h5>
            </div>
          </div>
         
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Relevant Board Number
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text" 
                disabled
                className={`form-control form-control-lg form-control-solid`}
                name="relevant_board_number"
                {...formik.getFieldProps("relevant_board_number")}
              /> 
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Title
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text" 
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "title"
                )}`}
                disabled
                name="title"
                {...formik.getFieldProps("title")}
              />
              {formik.touched.firstname && formik.errors.firstname ? (
                <div className="invalid-feedback">
                  {formik.errors.title}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Hospital
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text" 
                disabled
                className={`form-control form-control-lg form-control-solid`}
                name="hospital"
                {...formik.getFieldProps("hospital")}
              /> 
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Medical School
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text" 
                disabled
                className={`form-control form-control-lg form-control-solid`}
                name="medical_school"
                {...formik.getFieldProps("medical_school")}
              /> 
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              School Completion Date
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text" 
                disabled
                className={`form-control form-control-lg form-control-solid`}
                name="school_completion_date"
                {...formik.getFieldProps("school_completion_date")}
              /> 
            </div>
          </div>
        
         
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mt-10 mb-6">Contact Info</h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Reference Phone
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-phone"></i>
                  </span>
                </div>
                <input
                  type="text"
                  disabled
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "reference_phone"
                  )}`}
                  name="reference_phone"
                  {...formik.getFieldProps("reference_phone")}
                />
              </div>
              
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Email Address
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at"></i>
                  </span>
                </div>
                <input
                  type="email" 
                  disabled
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "reference_email"
                  )}`}
                  name="reference_email"
                  {...formik.getFieldProps("reference_email")}
                />
              </div> 
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              City/State/Country
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <input
                  type="text" 
                  className={`form-control form-control-lg form-control-solid`}
                  name="city_state_country"
                  disabled
                  {...formik.getFieldProps("city_state_country")}
                />
              </div>
            
            </div>
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Form */}
    </form>
  );
}

// export default connect(null, providers.actions)(ProviderPersonaInformation);
export default ProviderSpecialtyInformation
