import React, { useState, useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as actions from "../../_redux/providersActions";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import * as uiHelpers from "./ProviderAppointmentsUIHelpers";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "../column-formatters";
// import * as providers from "../../Providers";
import {
  ProviderAppointmentsUIProvider,
  useProviderAppointmentsUIContext,
} from "./ProviderAppointmentsUIContext";
import { useProvidersUIContext } from "../../ProvidersUIContext";

function ProviderAppointments({ history }) {
  const ProviderAppointmentsUIEvents = {
    openDetailProviderAppointmentsPage: (id) => {
      history.push(`/providers/${id}/appointments`);
    },
  };

  const providerAppointmentsUIContext = useProviderAppointmentsUIContext();

  const providerAppointmentsUIProps = useMemo(() => {
    return {
      ids: providerAppointmentsUIContext.ids,
      setIds: providerAppointmentsUIContext.setIds,
      queryParams: providerAppointmentsUIContext.queryParams,
      setQueryParams: providerAppointmentsUIContext.setQueryParams,
      openDetailProviderAppointmentTransactionPage:
        providerAppointmentsUIContext.openDetailProviderAppointmentTransactionPage,
    };
  }, [providerAppointmentsUIContext]);

  // Fields
  const [loading, setloading] = useState(false);
  const [pic, setPic] = useState("");
  const dispatch = useDispatch();
  const currentState = useSelector(
    (state) => state.providers.appointments,
    shallowEqual
  );
  const provider = useSelector(
    (state) => state.providers.provider,
    shallowEqual
  );

  useEffect(() => {
    // clear selections list
    providerAppointmentsUIProps.setIds([]);

    dispatch(
      actions.fetchProviderAppointments(
        provider.id,
        providerAppointmentsUIProps
      )
    );
  }, [providerAppointmentsUIProps.queryParams, dispatch]);
  // Methods

  const { totalCount, entities, listLoading } = currentState;

  // Table columns
  const columns = [
    {
      dataField: "uuid",
      text: "Reciept ID",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "appointment_type.name",
      text: "Type",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.AppointmentTypeColumnFormatter,
      sortCaret: sortCaret,
    },

    {
      dataField: "type",
      text: "Channel",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "user.username",
      text: "Patient",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.AppointmentStatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "session_start_time",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: columnFormatters.ActionsColumnFormatter,
    //   formatExtraData: {
    //     openDetailImmunizationPage: providerAppointmentsUIProps.openDetailImmunizationPage,
    //     openEditProductPage: providerAppointmentsUIProps.openEditProductPage,
    //     openDeleteProductDialog: providerAppointmentsUIProps.openDeleteProductDialog,
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: providerAppointmentsUIProps.queryParams.pageSize,
    page: providerAppointmentsUIProps.queryParams.pageNumber,
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // providerAppointmentsUIProps.openDetailProviderPage(row?.id)
    },
  };

  return (
    <>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Appointments
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            List of provider appointments
          </span>
        </div>
      </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  providerAppointmentsUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: providerAppointmentsUIProps.ids,
                  setIds: providerAppointmentsUIProps.setIds,
                })}
                rowEvents={rowEvents}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

// export default connect(null, providers.actions)(ProviderPersonaInformation);
export default ProviderAppointments;
