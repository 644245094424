import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/providersActions";
import { ProviderEditStatusDialogHeader } from "./ProviderEditStatusDialogHeader";
import { ProviderEditStatusForm } from "./ProviderEditStatusForm";

export function ProviderEditStatusDialog({ id, show, onHide }) {
  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, provider } = useSelector(
    (state) => ({
      actionsLoading: state.providers.actionsLoading,
      provider: state.providers.provider,
    }),
    shallowEqual
  );

  const saveProvider = (provider) => {
    // server request for updating provider

    dispatch(
      actions.updateProviderStatus(provider.id, {
        status: provider.status,
        organisation: provider.organisation,
      })
    ).then(() => onHide());
  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <ProviderEditStatusDialogHeader id={provider?.id} />
        <ProviderEditStatusForm
          saveProvider={saveProvider}
          actionsLoading={actionsLoading}
          provider={provider}
          onHide={onHide}
        />
      </Modal>
    </>
  );
}
