import axios from "axios";
import { baseURL } from "../../../../config";
import { buildFilterString } from "../../../utils/utils";

// export const PROVIDERS_URL = "api/products";
export const PROVIDERS_URL = `${baseURL}/control/providers`;

// READ
export function getAllProviders() {
  return axios.get(PROVIDERS_URL);
}

export function getProviderById(providerId) {
  return axios.get(`${PROVIDERS_URL}/${providerId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProviders(queryParams) {
  let startNumber =
    queryParams.pageNumber == 1
      ? 0
      : queryParams.pageNumber * queryParams.pageSize - 9;
  let filterString = buildFilterString(queryParams.filter);
  return axios.get(
    `${PROVIDERS_URL}?_start=${startNumber}&_limit=${queryParams.pageSize}&_sort=created_at:DESC${filterString}`
  );
  //   return axios.get(`${PROVIDERS_URL}`);
}
export function findProviderPayments(providerId, queryParams) {
  let qParams = queryParams.queryParams;
  let startNumber =
    qParams.pageNumber == 1 ? 0 : qParams.pageNumber * qParams.pageSize - 9;
  return axios.get(
    `${PROVIDERS_URL}/${providerId}/payments?_start=${startNumber}&_limit=${qParams.pageSize}&_sort=created_at:DESC`
  );
  //   return axios.get(`${PROVIDERS_URL}`);
}
export function findProviderAppointments(providerId, queryParams) {
  let qParams = queryParams.queryParams;
  let startNumber =
    qParams.pageNumber == 1 ? 0 : qParams.pageNumber * qParams.pageSize - 9;
  return axios.get(
    `${PROVIDERS_URL}/${providerId}/appointments?_start=${startNumber}&_limit=${qParams.pageSize}&_sort=session_start_time:DESC`
  );
  //   return axios.get(`${PROVIDERS_URL}`);
}

export function findProviderPatients(providerId, queryParams) {
  let qParams = queryParams.queryParams;
  let startNumber =
    qParams.pageNumber == 1 ? 0 : qParams.pageNumber * qParams.pageSize - 9;
  return axios.get(
    `${PROVIDERS_URL}/${providerId}/patients?_start=${startNumber}&_limit=${qParams.pageSize}&_sort=created_at:DESC`
  );
  //   return axios.get(`${PROVIDERS_URL}`);
}

export function updateProviderStatus(id, status) {
  return axios.put(`${PROVIDERS_URL}/${id}/status`, status);
}
