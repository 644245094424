import {createSlice} from "@reduxjs/toolkit";

const initialProvidersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  provider: undefined,
  lastError: null,
  payments:{
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    payment: undefined,
    lastError: null,
  },
  appointments:{
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    appointment: undefined,
    lastError: null,
  },
  patients:{
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    appointment: undefined,
    lastError: null,
  }
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const providersSlice = createSlice({
  name: "providers",
  initialState: initialProvidersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById
    providerFetched: (state, action) => {
      state.actionsLoading = false;
      state.provider = action.payload.provider;
      state.error = null;
    },
    // findProducts
    providersFetched: (state, action) => { 
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // find provider payments
    providerPaymentsFetched: (state, action) => { 
      const { totalCount, entities } = action.payload;
      state.payments.listLoading = false;
      state.payments.error = null;
      state.payments.entities = entities;
      state.payments.totalCount = totalCount;
    },
    providerAppointmentsFetched: (state, action) => { 
      const { totalCount, entities } = action.payload;
      state.appointments.listLoading = false;
      state.appointments.error = null;
      state.appointments.entities = entities;
      state.appointments.totalCount = totalCount;
    },
    
    providerPatientsFetched: (state, action) => { 
      const { totalCount, entities } = action.payload;
      state.patients.listLoading = false;
      state.patients.error = null;
      state.patients.entities = entities;
      state.patients.totalCount = totalCount;
    },
     // update Provider
     providerUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;  
      state.provider={...action.payload.provider};
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.provider.id) {
          return action.payload.provider;
        }
        return entity;
      }); 
  
    },
    
     
  }
});
