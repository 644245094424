/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  DropdownCustomToggler,
  DropdownMenu4,
} from "../../../../_metronic/_partials/dropdowns";

export function PaymentDetailList({ className, paymentData, history }) {
  const typeClass = "success";

  const goToEditStatus = () => {
    history.push(`/payments/${paymentData.id}/edit`);
  };

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            General Information
          </h3>
          {/* <div className="card-toolbar">
            <button
              onClick={() => {
                goToEditStatus();
              }}
              className="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
            >
              Status
            </button> */}
          {/* <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                variant="transparent"
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
               >
                <i className="ki ki-bold-more-hor" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu4 />
              </Dropdown.Menu>
            </Dropdown>             */}
          {/* </div> */}
        </div>

        {/* Body */}
        <div className="row card-body pt-2">
          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-paymentel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Flag.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Channel
                </a>
                <span className="text-muted">{paymentData?.channel}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-paymentel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Flag.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Amount Paid
                </a>
                <span className="text-muted">
                  {paymentData?.currency} {paymentData?.amount}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-paymentel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                >
                  Patient
                </a>
                <span className="text-muted">
                  {paymentData?.user?.first_name} {paymentData?.user?.last_name}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-paymentel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Shield-user.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                >
                  Provider
                </a>
                <span className="text-muted">
                  {paymentData?.provider?.title}{" "}
                  {paymentData?.provider?.user?.first_name}{" "}
                  {paymentData?.provider?.user?.last_name}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-paymentel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Settings-1.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Receipt ID
                </a>
                <span className="text-muted">{paymentData?.uuid}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-paymentel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Settings-1.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  3rd Party Ref
                </a>
                <span className="text-muted">{paymentData?.reference}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-paymentel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Created Date
                </a>
                <span className="text-muted">{paymentData?.created_at}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-paymentel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Status
                </a>
                <span className="text-muted">{paymentData?.status}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
