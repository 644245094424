import * as requestFromServer from "./patientsCrud";
import { patientsSlice, callTypes } from "./PatientsSlice";

const { actions } = patientsSlice;

export const fetchPatients = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPatients(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;

      // dispatch(actions.productsFetched({ totalCount:0, entities:res }));
      dispatch(
        actions.patientsFetched({ totalCount: totalCount, entities: entities })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPatient = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.patientFetched({ patient: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPatientById(id)
    .then((response) => {
      const product = response.data;
      dispatch(actions.patientFetched({ patient: product }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchPatientPayments = (id, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPatientPayments(id, queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;

      // dispatch(actions.productsFetched({ totalCount:0, entities:res }));
      dispatch(
        actions.patientPaymentsFetched({
          totalCount: totalCount,
          entities: entities,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchPatientAppointments = (id, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPatientAppointments(id, queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;

      // dispatch(actions.productsFetched({ totalCount:0, entities:res }));
      dispatch(
        actions.patientAppointmentsFetched({
          totalCount: totalCount,
          entities: entities,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPatientMedications = (id, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPatientMedications(id, queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;

      dispatch(
        actions.patientMedicationsFetched({
          totalCount: totalCount,
          entities: entities,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find medications";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPatientLabs = (id, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPatientLabs(id, queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;

      dispatch(
        actions.patientLabsFetched({
          totalCount: totalCount,
          entities: entities,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find labs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPatientImmunizations = (id, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPatientImmunizations(id, queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;

      dispatch(
        actions.patientImmunizationsFetched({
          totalCount: totalCount,
          entities: entities,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find labs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPatientPatients = (id, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPatientPatients(id, queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;

      // dispatch(actions.productsFetched({ totalCount:0, entities:res }));
      dispatch(
        actions.patientPatientsFetched({
          totalCount: totalCount,
          entities: entities,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
