import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/paymentsActions";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { ListsWidget1 } from "../../../../_metronic/_partials/widgets";
import { PaymentDetailList } from "./PaymentDetailList";
import { PaymentEditStatusDialog } from "./payment-edit-status-dialog/PaymentEditStatusDialog";
import { ProviderProfileOverview } from "../../Providers/provider-details/ProviderProfileOverview";

export const PaymentDetails = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Payment Details");

  const dispatch = useDispatch();
  const [refreshTime, setRefreshTime] = useState(null);
  const { actionsLoading, payment } = useSelector(
    (state) => ({
      actionsLoading: state.payments.actionsLoading,
      payment: state.payments.payment,
    }),
    shallowEqual
  );

  useEffect(() => {
    console.log(payment);
    dispatch(actions.fetchPayment(id));
  }, [id, dispatch, refreshTime]);

  const btnRef = useRef();
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProductsList = () => {
    history.push(`/payments`);
  };

  const goToEditStatus = (id) => {
    history.push(`/payments/${id}/edit`);
  };

  return (
    <Card>
      <CardHeader title={"Payment Details"}>
        <CardHeaderToolbar>
          <button
            onClick={() => {
              goToEditStatus(id);
            }}
            className="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
          >
            Status
          </button>
          <button
            type="button"
            onClick={backToProductsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {/* {`  `}
          <button className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveProductClick}
          >
            Save
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <PaymentDetailList
          className="card-stretch gutter-b"
          paymentData={payment}
          history={history}
        />
        <div className="flex-row-fluid ml-lg-8">
          <Switch>
            {/* <Redirect
              from={`/payments/${id}`}
              exact={true}
              to={`/payments/${id}/details`}
            /> */}
            {/* <Route
              path={`/payments/${id}/details`}
              component={ProviderProfileOverview}
            /> */}

            <Route path={`/payments/${id}/edit`}>
              {({ history, match }) => {
                return (
                  <PaymentEditStatusDialog
                    show={match != null}
                    id={match && match.params.id}
                    onHide={() => {
                      history.push(`/payments/${id}`);
                      setRefreshTime(new Date().getTime());
                    }}
                  />
                );
              }}
            </Route>
          </Switch>
        </div>
      </CardBody>
    </Card>
  );
};
