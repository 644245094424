import React, { useState, useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitPatient, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as actions from "../../_redux/patientsActions";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import * as uiHelpers from "./PatientPaymentTransactionsUIHelpers";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "../column-formatters";
// import * as patients from "../../Patients";
import { usePatientPaymentTransactionsUIContext } from "./PatientPaymentTransactionsUIContext";
import { usePatientsUIContext } from "../../PatientsUIContext";

function PatientPaymentTransactions({ history }) {
  const patientPaymentTransactionsUIContext = usePatientPaymentTransactionsUIContext();

  const patientPaymentTransactionsUIProps = useMemo(() => {
    return {
      ids: patientPaymentTransactionsUIContext.ids,
      setIds: patientPaymentTransactionsUIContext.setIds,
      queryParams: patientPaymentTransactionsUIContext.queryParams,
      setQueryParams: patientPaymentTransactionsUIContext.setQueryParams,
      openDetailPatientPaymentTransactionPage:
        patientPaymentTransactionsUIContext.openDetailPatientPaymentTransactionPage,
    };
  }, [patientPaymentTransactionsUIContext]);

  // Fields
  const [loading, setloading] = useState(false);
  const [pic, setPic] = useState("");
  const dispatch = useDispatch();
  const currentState = useSelector(
    (state) => state.patients.payments,
    shallowEqual
  );
  const patient = useSelector((state) => state.patients.patient, shallowEqual);

  useEffect(() => {
    // clear selections list
    patientPaymentTransactionsUIProps.setIds([]);

    dispatch(
      actions.fetchPatientPayments(
        patient.id,
        patientPaymentTransactionsUIProps
      )
    );
  }, [patientPaymentTransactionsUIProps.queryParams, dispatch]);
  // Methods

  const { totalCount, entities, listLoading } = currentState;

  // Table columns
  const columns = [
    {
      dataField: "uuid",
      text: "Reciept ID",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.PaymentAmountColumnFormatter,
      sortCaret: sortCaret,
    },

    {
      dataField: "channel",
      text: "Channel",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "reference",
      text: "3P Reference",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Date Paid",
      sort: true,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: columnFormatters.ActionsColumnFormatter,
    //   formatExtraData: {
    //     openDetailImmunizationPage: patientPaymentTransactionsUIProps.openDetailImmunizationPage,
    //     openEditProductPage: patientPaymentTransactionsUIProps.openEditProductPage,
    //     openDeleteProductDialog: patientPaymentTransactionsUIProps.openDeleteProductDialog,
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: patientPaymentTransactionsUIProps.queryParams.pageSize,
    page: patientPaymentTransactionsUIProps.queryParams.pageNumber,
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // patientPaymentTransactionsUIProps.openDetailPatientPage(row?.id)
    },
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  patientPaymentTransactionsUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: patientPaymentTransactionsUIProps.ids,
                  setIds: patientPaymentTransactionsUIProps.setIds,
                })}
                rowEvents={rowEvents}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

// export default connect(null, patients.actions)(PatientPersonaInformation);
export default PatientPaymentTransactions;
