/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  DropdownCustomToggler,
  DropdownMenu4,
} from "../../../../_metronic/_partials/dropdowns";

export function MedicationDetailList({ className, medicationData }) {
  const typeClass = medicationData?.type == "system" ? "success" : "primary";

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            General Information
          </h3>
          {/* <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                variant="transparent"
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
               >
                <i className="ki ki-bold-more-hor" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu4 />
              </Dropdown.Menu>
            </Dropdown>            
          </div> */}
        </div>

        {/* Body */}
        <div className="row card-body pt-2">
          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-medicationel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Flag.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Medication Type
                </a>
                <span className="text-muted">{medicationData?.name}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-medicationel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                >
                  Patient
                </a>
                <span className="text-muted">
                  {medicationData?.user?.first_name}{" "}
                  {medicationData?.user?.last_name}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-medicationel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Shield-user.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                >
                  Provider
                </a>
                <span className="text-muted">
                  {medicationData?.provider?.title}{" "}
                  {medicationData?.appointment?.provider?.user?.first_name}{" "}
                  {medicationData?.appointment?.provider?.user?.last_name}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-medicationel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Settings-1.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Type
                </a>
                <span className="text-muted">{medicationData?.type}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-medicationel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Start Date
                </a>
                <span className="text-muted">{medicationData?.start_date}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-medicationel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  End Date
                </a>
                {medicationData?.is_end_date ? (
                  <span className="text-muted">{medicationData?.end_date}</span>
                ) : (
                  <span className="text-muted">-</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-medicationel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  No. of daily intakes
                </a>
                <span className="text-muted">
                  {medicationData?.number_of_daily_intakes}
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-medicationel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Status
                </a>
                <span className="text-muted">{medicationData?.status}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-medicationel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Reason
                </a>
                <span className="text-muted">{medicationData?.reason}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-medicationel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Instructions
                </a>
                <span className="text-muted">
                  {medicationData?.instructions}
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xxl-6">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-medicationel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Reminder times (GMT)
                </a>
                <div>
                  {medicationData?.reminders?.map((res, index) => (
                    <span className="text-muted pr-5  " key={index}>
                      {res.time}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
