import * as requestFromServer from "./providersCrud";
import { providersSlice, callTypes } from "./ProductsSlice";

const { actions } = providersSlice;

export const fetchProviders = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProviders(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;

      // dispatch(actions.productsFetched({ totalCount:0, entities:res }));
      dispatch(
        actions.providersFetched({ totalCount: totalCount, entities: entities })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchProvider = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.providerFetched({ provider: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProviderById(id)
    .then((response) => {
      const product = response.data;
      dispatch(actions.providerFetched({ provider: product }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchProviderPayments = (id, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProviderPayments(id, queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;

      // dispatch(actions.productsFetched({ totalCount:0, entities:res }));
      dispatch(
        actions.providerPaymentsFetched({
          totalCount: totalCount,
          entities: entities,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchProviderAppointments = (id, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProviderAppointments(id, queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;

      // dispatch(actions.productsFetched({ totalCount:0, entities:res }));
      dispatch(
        actions.providerAppointmentsFetched({
          totalCount: totalCount,
          entities: entities,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchProviderPatients = (id, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProviderPatients(id, queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;

      // dispatch(actions.productsFetched({ totalCount:0, entities:res }));
      dispatch(
        actions.providerPatientsFetched({
          totalCount: totalCount,
          entities: entities,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateProviderStatus = (id, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateProviderStatus(id, status)
    .then((response) => {
      dispatch(actions.providerUpdated({ provider: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update provider";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
