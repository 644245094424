import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/immunizationsActions";
import {useSubheader} from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
  } from "../../../../_metronic/_partials/controls";
  import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { ListsWidget1 } from "../../../../_metronic/_partials/widgets";
import { ImmunizationDetailList } from "./ImmunizationDetailList";

export const ImmunizationDetails = ({
    history,
    match: {
      params: { id },
    },
  }) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Immunization Details");

  const dispatch = useDispatch();

  const { actionsLoading, immunization } = useSelector(
    (state) => ({
      actionsLoading: state.immunizations.actionsLoading,
      immunization: state.immunizations.immunization,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchImmunization(id));
  }, [id, dispatch]);


  const btnRef = useRef();  
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProductsList = () => {
    history.push(`/immunizations`);
  };

  return(
    <Card> 
    <CardHeader title={"Immunization Details"}>
    <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToProductsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {/* {`  `}
          <button className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveProductClick}
          >
            Save
          </button> */}
        </CardHeaderToolbar>
    </CardHeader>
    <CardBody>
   
 
    <ImmunizationDetailList className="card-stretch gutter-b" immunizationData={immunization}/>
    </CardBody>
  </Card>
  )
};
