import React, { useEffect } from "react";
import { ContentRoute, useSubheader } from "../../../_metronic/layout";
import { DashboardCard } from "./DashboardCard";

export default function MainDashboardPage({ history }) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Dashboard");

  return (
    // <ProvidersUIProvider providersUIEvents={providersUIEvents}>

    <DashboardCard tablesClasses={"col-lg-6 col-xxl-4 order-1 order-xxl-1"} />
    // </ProvidersUIProvider>
  );
}
