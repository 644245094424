import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/paymentsActions";
import { PaymentEditStatusDialogHeader } from "./PaymentEditStatusDialogHeader";
import { PaymentEditStatusForm } from "./PaymentEditStatusForm";

export function PaymentEditStatusDialog({ id, show, onHide }) {
  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, payment } = useSelector(
    (state) => ({
      actionsLoading: state.payments.actionsLoading,
      payment: state.payments.payment,
    }),
    shallowEqual
  );

  // server request for saving customer
  const savePayment = (payment) => {
    // server request for updating customer
    dispatch(
      actions.updatePaymentStatus(payment.id, { status: payment.status })
    ).then(() => onHide());
  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <PaymentEditStatusDialogHeader id={payment?.id} />
        <PaymentEditStatusForm
          savePayment={savePayment}
          actionsLoading={actionsLoading}
          payment={payment}
          onHide={onHide}
        />
      </Modal>
    </>
  );
}
