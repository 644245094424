import React, { useState, useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitPatient, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as actions from "../../_redux/patientsActions";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import * as uiHelpers from "./PatientMedicationsUIHelpers";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "../column-formatters";
// import * as patients from "../../Patients";
import {
  PatientMedicationsUIPatient,
  usePatientMedicationsUIContext,
} from "./PatientMedicationsUIContext";
import { usePatientsUIContext } from "../../PatientsUIContext";

function PatientMedications({ history }) {
  const PatientMedicationsUIEvents = {
    openDetailPatientMedicationsPage: (id) => {
      history.push(`/patients/${id}/medications`);
    },
  };

  const patientMedicationsUIContext = usePatientMedicationsUIContext();

  const patientMedicationsUIProps = useMemo(() => {
    return {
      ids: patientMedicationsUIContext.ids,
      setIds: patientMedicationsUIContext.setIds,
      queryParams: patientMedicationsUIContext.queryParams,
      setQueryParams: patientMedicationsUIContext.setQueryParams,
      openDetailPatientAppointmentTransactionPage:
        patientMedicationsUIContext.openDetailPatientAppointmentTransactionPage,
    };
  }, [patientMedicationsUIContext]);

  // Fields
  const [loading, setloading] = useState(false);
  const [pic, setPic] = useState("");
  const dispatch = useDispatch();
  const currentState = useSelector(
    (state) => state.patients.medications,
    shallowEqual
  );
  const patient = useSelector((state) => state.patients.patient, shallowEqual);

  useEffect(() => {
    // clear selections list
    patientMedicationsUIProps.setIds([]);

    dispatch(
      actions.fetchPatientMedications(patient.id, patientMedicationsUIProps)
    );
  }, [patientMedicationsUIProps.queryParams, dispatch]);
  // Methods

  const { totalCount, entities, listLoading } = currentState;
  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Provider",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.PatientMedicationNameFormatter,
    },
    {
      dataField: "start_date",
      text: "Start date",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "number_of_daily_intakes",
      text: "Daily Intake",
      sort: true,
      sortCaret: sortCaret,
    },

    {
      dataField: "type",
      text: "Type",
      sort: true,
      sortCaret: sortCaret,
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.AppointmentStatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: columnFormatters.ActionsColumnFormatter,
    //   formatExtraData: {
    //     openDetailImmunizationPage: patientMedicationsUIProps.openDetailImmunizationPage,
    //     openEditProductPage: patientMedicationsUIProps.openEditProductPage,
    //     openDeleteProductDialog: patientMedicationsUIProps.openDeleteProductDialog,
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: patientMedicationsUIProps.queryParams.pageSize,
    page: patientMedicationsUIProps.queryParams.pageNumber,
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // patientMedicationsUIProps.openDetailPatientPage(row?.id)
    },
  };

  return (
    <>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Medications
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            List of patient medications
          </span>
        </div>
      </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  patientMedicationsUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: patientMedicationsUIProps.ids,
                  setIds: patientMedicationsUIProps.setIds,
                })}
                rowEvents={rowEvents}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

// export default connect(null, patients.actions)(PatientPersonaInformation);
export default PatientMedications;
