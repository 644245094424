export function buildFilterString(filterParams) {
  let filterObj = "";

  for (var key in filterParams) {
    if (filterParams.hasOwnProperty(key)) {
      if (filterParams[key].length > 0)
        filterObj += `&_${key}=${filterParams[key]}`;
    }
  }

  return filterObj;
}
