import axios from "axios";
import { baseURL } from "../../../../config";
import { buildFilterString } from "../../../utils/utils";

// export const PATIENTS_URL = "api/products";
export const USERS_URL = `${baseURL}/users-permissions/control/users`;
export const PATIENTS_URL = `${baseURL}/control/patients`;

// READ
export function getAllPatients() {
  return axios.get(USERS_URL);
}

export function getPatientById(patientId) {
  return axios.get(`${USERS_URL}/${patientId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findPatients(queryParams) {
  let startNumber =
    queryParams.pageNumber == 1
      ? 0
      : queryParams.pageNumber * queryParams.pageSize - 9;
  let filterString = buildFilterString(queryParams.filter);
  return axios.get(
    `${USERS_URL}?_start=${startNumber}&_limit=${queryParams.pageSize}&_sort=created_at:DESC${filterString}`
  );
  //   return axios.get(`${PATIENTS_URL}`);
}
export function findPatientPayments(patientId, queryParams) {
  let qParams = queryParams.queryParams;
  let startNumber =
    qParams.pageNumber == 1 ? 0 : qParams.pageNumber * qParams.pageSize - 9;
  return axios.get(
    `${PATIENTS_URL}/${patientId}/payments?_start=${startNumber}&_limit=${qParams.pageSize}&_sort=created_at:DESC`
  );
  //   return axios.get(`${PATIENTS_URL}`);
}
export function findPatientAppointments(patientId, queryParams) {
  let qParams = queryParams.queryParams;
  let startNumber =
    qParams.pageNumber == 1 ? 0 : qParams.pageNumber * qParams.pageSize - 9;
  return axios.get(
    `${PATIENTS_URL}/${patientId}/appointments?_start=${startNumber}&_limit=${qParams.pageSize}&_sort=session_start_time:DESC`
  );
  //   return axios.get(`${PATIENTS_URL}`);
}
export function findPatientMedications(patientId, queryParams) {
  let qParams = queryParams.queryParams;
  let startNumber =
    qParams.pageNumber == 1 ? 0 : qParams.pageNumber * qParams.pageSize - 9;
  return axios.get(
    `${PATIENTS_URL}/${patientId}/medications?_start=${startNumber}&_limit=${qParams.pageSize}&_sort=updated_at:DESC`
  );
  //   return axios.get(`${PATIENTS_URL}`);
}
export function findPatientLabs(patientId, queryParams) {
  let qParams = queryParams.queryParams;
  let startNumber =
    qParams.pageNumber == 1 ? 0 : qParams.pageNumber * qParams.pageSize - 9;
  return axios.get(
    `${PATIENTS_URL}/${patientId}/labs?_start=${startNumber}&_limit=${qParams.pageSize}&_sort=updated_at:DESC`
  );
  //   return axios.get(`${PATIENTS_URL}`);
}
export function findPatientImmunizations(patientId, queryParams) {
  let qParams = queryParams.queryParams;
  let startNumber =
    qParams.pageNumber == 1 ? 0 : qParams.pageNumber * qParams.pageSize - 9;
  return axios.get(
    `${PATIENTS_URL}/${patientId}/immunizations?_start=${startNumber}&_limit=${qParams.pageSize}&_sort=updated_at:DESC`
  );
  //   return axios.get(`${PATIENTS_URL}`);
}

export function findPatientPatients(patientId, queryParams) {
  let qParams = queryParams.queryParams;
  let startNumber =
    qParams.pageNumber == 1 ? 0 : qParams.pageNumber * qParams.pageSize - 9;
  return axios.get(
    `${PATIENTS_URL}/${patientId}/patients?_start=${startNumber}&_limit=${qParams.pageSize}`
  );
  //   return axios.get(`${PATIENTS_URL}`);
}
