export const defaultSorted = [{ dataField: "id", order: "asc" }];
// export const PatientStatusCssClasses = ["danger", "success", "info", ""];
export const PatientStatusCssClasses = {
  active: "success",
  pending: "info",
  cancelled: "danger",
};
// export const PatientStatusTitles = ["suspended", "active", "pending", ""];
export const PatientStatusTitles = {
  active: "Active",
  pending: "Pending",
  cancelled: "Cancelled",
};

export const sizePerPageList = [{ text: "10", value: 10 }];
export const initialFilter = {
  filter: {
    model: "",
    manufacture: "",
    VINCode: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10,
};
