import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ProviderPaymentTransactionsUIHelpers";

const ProviderPaymentTransactionssUIContext = createContext();

export function useProviderPaymentTransactionsUIContext() {
  return useContext(ProviderPaymentTransactionssUIContext);
}

export const ProviderPaymentTransactionsUIConsumer = ProviderPaymentTransactionssUIContext.Consumer;

export function ProviderPaymentTransactionsUIProvider({ ProviderPaymentTransactionsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams, 
    openDetailProviderPaymentTransactionPage: ProviderPaymentTransactionsUIEvents.openDetailProviderPaymentTransactionPage
  };

  return (
    <ProviderPaymentTransactionssUIContext.Provider value={value}>
      {children}
    </ProviderPaymentTransactionssUIContext.Provider>
  );
}
