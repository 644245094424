import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";

export function PaymentEditStatusDialogHeader({ id }) {
  // Customers Redux state
  const { payment, actionsLoading } = useSelector(
    (state) => ({
      payment: state.payments.payment,
      actionsLoading: state.payments.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = id ? "" : "New Payment";
    if (payment && id) {
      _title = `Edit Payment Status for channel: ${payment?.channel}`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [payment, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
