import * as requestFromServer from "./dashboardCrud";
import { dashboardSlice, callTypes } from "./DashboardSlice";

const { actions } = dashboardSlice;

export const fetchDashboardData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getMainDashboard()
    .then((response) => {
      dispatch(actions.dashboardDataFetched(response.data));
    })
    .catch((error) => {
      error.clientMessage = "Can't find dashboard data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
