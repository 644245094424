import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/providersActions";
import { useSubheader } from "../../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import {
  DropdownCustomToggler,
  DropdownMenu4,
} from "../../../../_metronic/_partials/dropdowns";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { ListsWidget1 } from "../../../../_metronic/_partials/widgets";
import { ProviderProfileOverview } from "./ProviderProfileOverview";
import ProviderPersonalInformation from "./ProviderPersonalInformation";
import ProviderSpecialtyInformation from "./ProviderSpecialtyInformation";
import ProviderDocuments from "./ProviderDocuments";
import ProviderPaymentInformation from "./ProviderPaymentInformation";
import ProviderPaymentTransactions from "./provider-payments/ProviderPaymentTransactions";
import { ProviderPaymentTransactionsUIProvider } from "./provider-payments/ProviderPaymentTransactionsUIContext";
import { ProviderAppointmentsUIProvider } from "./provider-appointments/ProviderAppointmentsUIContext";
import ProviderAppointments from "./provider-appointments/ProviderAppointments";
import { ProviderPatientsUIProvider } from "./provider-patients/ProviderPatientsUIContext";
import ProviderPatients from "./provider-patients/ProviderPatients";
import { ProviderEditStatusDialog } from "./provider-edit-status-dialog/ProviderEditStatusDialog";
import { ProvidersUIProvider } from "../ProvidersUIContext";
// import { ImmunizationDetailList } from "./ImmunizationDetailList";

export const ProviderDetails = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Provider Details");

  const dispatch = useDispatch();

  const { actionsLoading, provider } = useSelector(
    (state) => ({
      actionsLoading: state.providers.actionsLoading,
      provider: state.providers.provider,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchProvider(id));
  }, [id, dispatch]);

  const btnRef = useRef();
  const goToEditStatus = (id) => {
    history.push(`/providers/${id}/edit`);
  };

  const backToProvidersList = () => {
    history.push(`/providers`);
  };

  const ProviderPaymentTransactionsUIEvents = {
    openDetailProviderPaymentTransactionsPage: (id) => {
      history.push(`/providers/${id}/payment-transactions`);
    },
  };
  const ProviderAppointmentsUIEvents = {
    openDetailProviderAppointmentsPage: (id) => {
      history.push(`/providers/${id}/appointments`);
    },
  };
  const ProviderPatientsUIEvents = {
    openDetailProviderPatientsPage: (id) => {
      history.push(`/providers/${id}/patients`);
    },
  };
  const ProviderEditStatusUIEvents = {
    openProviderEditStatusDialog: (id) => {
      history.push(`/providers/${id}/edit`);
    },
  };

  // const goToEditStatus = (id)=>{
  //     history.push(`/providers/${id}/edit`);
  // };

  return (
    <Card>
      <CardHeader title={"Provider Details"}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToProvidersList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {/* {`  `}
          <button className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveProductClick}
          >
            Save
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="d-flex flex-row">
          <>
            {provider && (
              <div
                className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
                id="kt_profile_aside"
              >
                <div className="card card-custom card-stretch">
                  {/* begin::Body */}
                  <div className="card-body pt-4">
                    {/* begin::User */}
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                        <div
                          className="symbol-label"
                          style={{
                            backgroundImage: `url(${
                              provider?.user?.profile_picture?.formats?.medium
                                ?.url
                                ? provider?.user?.profile_picture?.formats
                                    ?.medium?.url
                                : provider?.user?.profile_picture?.url
                            })`,
                          }}
                        ></div>
                        {/* style="background-i
                  mage:url('/metronic/theme/html/demo1/dist/assets/media/users/300_21.jpg')" */}
                        <i className="symbol-badge bg-success"></i>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                        >
                          {provider?.title} {provider?.user.first_name}{" "}
                          {provider?.user.last_name}
                        </a>
                        <div className="text-muted">
                          {provider?.specialty?.name}
                        </div>
                        <div className="mt-2">
                          {/* <a
                            href="#"
                            className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                          >
                            Chat
                          </a> */}
                          <button
                            onClick={() => {
                              goToEditStatus(provider?.id);
                            }}
                            className="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
                          >
                            Status
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold mr-2">Email:</span>
                        <span className="text-muted text-hover-primary">
                          {provider?.user.email}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold mr-2">Phone:</span>
                        <span className="text-muted">{provider?.user.tel}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold mr-2">Status:</span>
                        <span className="text-muted">{provider?.status} </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="font-weight-bold mr-2">
                          Organisation:
                        </span>
                        <span className="text-muted">
                          {provider?.organisation?.name}{" "}
                        </span>
                      </div>
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                      {/* <div className="navi-item mb-2">
                        <NavLink
                          to={`/providers/${provider?.id}/profile-overview`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Design/Layers.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Profile Overview
                          </span>
                        </NavLink>
                      </div> */}
                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/providers/${provider?.id}/personal-information`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/User.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Personal Information
                          </span>
                        </NavLink>
                      </div>
                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/providers/${provider?.id}/specialty-information`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Code/Compiling.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Specialty Information
                          </span>
                        </NavLink>
                      </div>
                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/providers/${provider?.id}/documents`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Shield-user.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Documents
                          </span>
                          {/* <span className="navi-label">
                      <span className="label label-light-danger label-rounded font-weight-bold">
                        5
                      </span>
                    </span> */}
                        </NavLink>
                      </div>

                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/providers/${provider?.id}/payment-information`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Shield-user.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Payment Information
                          </span>
                        </NavLink>
                      </div>

                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/providers/${provider?.id}/appointments`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Shield-user.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Appointments
                          </span>
                        </NavLink>
                      </div>
                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/providers/${provider?.id}/patients`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Shield-user.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Patients
                          </span>
                        </NavLink>
                      </div>

                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/providers/${provider?.id}/payment-transactions`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Shield-user.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Transactions
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    {/* end::Nav */}
                  </div>
                  {/* end::Body */}
                </div>
              </div>
            )}
          </>
          <div className="flex-row-fluid ml-lg-8">
            <Switch>
              <Redirect
                from={`/providers/${provider?.id}`}
                exact={true}
                to={`/providers/${provider?.id}/personal-information`}
              />
              <Route
                path={`/providers/${provider?.id}/profile-overview`}
                component={ProviderProfileOverview}
              />
              {/* <Route
            path={`/providers/${provider?.id}`}
            component={ProviderProfileOverview}
          /> */}
              <Route
                path={`/providers/${provider?.id}/personal-information`}
                component={ProviderPersonalInformation}
              />
              <Route
                path={`/providers/${provider?.id}/specialty-information`}
                component={ProviderSpecialtyInformation}
              />
              <Route
                path={`/providers/${provider?.id}/documents`}
                component={ProviderDocuments}
              />
              <Route
                path={`/providers/${provider?.id}/payment-information`}
                component={ProviderPaymentInformation}
              />

              <Route path={`/providers/${provider?.id}/appointments`}>
                <ProviderAppointmentsUIProvider
                  ProviderAppointmentsUIEvents={ProviderAppointmentsUIEvents}
                >
                  <ProviderAppointments />
                </ProviderAppointmentsUIProvider>
              </Route>

              <Route
                path={`/providers/${provider?.id}/payment-transactions`}
                // component={ProviderPaymentTransactions}
              >
                <ProviderPaymentTransactionsUIProvider
                  ProviderPaymentTransactionsUIEvents={
                    ProviderPaymentTransactionsUIEvents
                  }
                >
                  <ProviderPaymentTransactions />
                </ProviderPaymentTransactionsUIProvider>
              </Route>

              <Route
                path={`/providers/${provider?.id}/patients`}
                // component={ProviderPaymentTransactions}
              >
                <ProviderPatientsUIProvider
                  ProviderPatientsUIEvents={ProviderPatientsUIEvents}
                >
                  <ProviderPatients />
                </ProviderPatientsUIProvider>
              </Route>

              <Route path={`/providers/${provider?.id}/edit`}>
                {({ history, match }) => (
                  <ProviderEditStatusDialog
                    show={match != null}
                    id={match && match.params.id}
                    onHide={() => {
                      history.push(`/providers/${provider?.id}`);
                    }}
                  />
                )}
              </Route>

              {/* <Route
            path="/user-profile/profile-overview"
            component={ProviderProfileOverview}
          />
          <Route
            path="/user-profile/account-information"
            component={AccountInformation}
          />
          <Route
            path="/user-profile/change-password"
            component={ChangePassword}
          />
          <Route
            path="/user-profile/email-settings"
            component={EmailSettings}
          />
          <Route
            path="/user-profile/personal-information"
            component={PersonaInformation}
          /> */}
            </Switch>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
