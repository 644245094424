// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export function PatientMedicationNameFormatter(cellContent, row) {
  let providerName =
    row.type == "system"
      ? `${row?.provider?.title} ${row?.provider?.user?.first_name} ${row?.provider?.user?.last_name}`
      : row?.personal_provider;

  let providerPic = null;
  if (row.type == "system") {
    providerPic = row?.provider?.user?.profile_picture?.formats?.medium?.url
      ? row?.provider?.user?.profile_picture?.formats?.medium?.url
      : row?.provider?.user?.profile_picture?.url;
  }
  return (
    <div className="d-flex align-items-center">
      <div className="symbol symbol-30 symbol-light-primary mr-5">
        {" "}
        <div
          className="symbol-label"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/icons/medicine.png"
            )})`,
            backgroundSize: "auto",
          }}
        >
          {/* <SVG
            className="h-75 align-self-end"
            src={toAbsoluteUrl("/media/icons/medicine.png")}
          ></SVG> */}
        </div>{" "}
      </div>
      <div className="d-flex flex-column flex-grow-1 font-weight-bold">
        {row?.name}
        <span className="text-muted">{row?.reason}</span>
      </div>
    </div>
  );
}
