import { createSlice } from "@reduxjs/toolkit";

const initialProvidersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  patient: undefined,
  lastError: null,
  payments: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    payment: undefined,
    lastError: null,
  },
  appointments: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    appointment: undefined,
    lastError: null,
  },
  medications: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    medication: undefined,
    lastError: null,
  },
  labs: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    lab: undefined,
    lastError: null,
  },
  immunizations: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    immunization: undefined,
    lastError: null,
  },
  patients: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    appointment: undefined,
    lastError: null,
  },
  orgpatients: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    appointment: undefined,
    lastError: null,
  },
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const orgPatientsSlice = createSlice({
  name: "orgpatients",
  initialState: initialProvidersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById
    patientFetched: (state, action) => {
      state.actionsLoading = false;
      state.patient = action.payload.patient;
      state.error = null;
    },
    // findProducts
    patientsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // getProductById
    orgPatientFetched: (state, action) => {
      state.actionsLoading = false;
      state.patient = action.payload.patient;
      state.error = null;
    },
    // findProducts
    orgPatientsFetched: (state, action) => {
      console.log(action.payload);
      const { totalCount, entities } = action.payload;
      state.orgpatients.listLoading = false;
      state.orgpatients.error = null;
      state.orgpatients.entities = entities;
      state.orgpatients.totalCount = totalCount;
    },
    // find patient payments
    patientPaymentsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.payments.listLoading = false;
      state.payments.error = null;
      state.payments.entities = entities;
      state.payments.totalCount = totalCount;
    },
    patientAppointmentsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.appointments.listLoading = false;
      state.appointments.error = null;
      state.appointments.entities = entities;
      state.appointments.totalCount = totalCount;
    },
    patientMedicationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.medications.listLoading = false;
      state.medications.error = null;
      state.medications.entities = entities;
      state.medications.totalCount = totalCount;
    },
    patientLabsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.labs.listLoading = false;
      state.labs.error = null;
      state.labs.entities = entities;
      state.labs.totalCount = totalCount;
    },
    patientImmunizationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.immunizations.listLoading = false;
      state.immunizations.error = null;
      state.immunizations.entities = entities;
      state.immunizations.totalCount = totalCount;
    },

    patientPatientsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.patients.listLoading = false;
      state.patients.error = null;
      state.patients.entities = entities;
      state.patients.totalCount = totalCount;
    },
  },
});
