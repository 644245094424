import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/medicationsActions";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { ListsWidget1 } from "../../../../_metronic/_partials/widgets";
import { MedicationDetailList } from "./MedicationDetailList";

export const MedicationDetails = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Medication Details");

  const dispatch = useDispatch();

  const { actionsLoading, medication } = useSelector(
    (state) => ({
      actionsLoading: state.medications.actionsLoading,
      medication: state.medications.medication,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchMedication(id));
  }, [id, dispatch]);

  const btnRef = useRef();
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProductsList = () => {
    history.push(`/medications`);
  };
  return (
    <Card>
      <CardHeader title={"Medication Details"}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToProductsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {/* {`  `}
          <button className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveProductClick}
          >
            Save
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <MedicationDetailList
          className="card-stretch gutter-b"
          medicationData={medication}
        />
      </CardBody>
    </Card>
  );
};
