/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  DropdownCustomToggler,
  DropdownMenu4,
} from "../../../../_metronic/_partials/dropdowns";

export function LabDetailList({ className, labData }) {
  const typeClass = labData?.type == "system" ? "success" : "primary";

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            General Information
          </h3>
          {/* <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                variant="transparent"
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
               >
                <i className="ki ki-bold-more-hor" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu4 />
              </Dropdown.Menu>
            </Dropdown>            
          </div> */}
        </div>

        {/* Body */}
        <div className="row card-body pt-2">
          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-label">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Flag.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Lab Type
                </a>
                <span className="text-muted">{labData?.lab_type?.name}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-label">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                >
                  Patient
                </a>
                <span className="text-muted">
                  {labData?.user?.first_name} {labData?.user?.last_name}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-label">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Shield-user.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                >
                  Provider
                </a>
                <span className="text-muted">
                  {labData?.provider?.title}{" "}
                  {labData?.provider?.user?.first_name}{" "}
                  {labData?.provider?.user?.last_name}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-label">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Settings-1.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Type
                </a>
                <span className="text-muted">{labData?.type}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-label">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Date
                </a>
                <span className="text-muted">{labData?.date}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-label">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Hospital
                </a>
                <span className="text-muted">{labData?.hospital}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body pt-2">
          <h5 className="card-title font-weight-bolder text-dark">Images</h5>
          <div className="row pt-2">
            {labData?.images?.map((res) => {
              return (
                <div key={res?.id} className="col-lg-3 col-xxl-3">
                  <div
                    className="bgi-no-repeat bgi-size-cover rounded min-h-180px w-100"
                    style={{ backgroundImage: `url('${res?.url}')` }}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
