import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "./_redux/dashboardActions";
import { Dropdown } from "react-bootstrap";
import {
  DropdownCustomToggler,
  DropdownMenu1,
  DropdownMenu2,
} from "../../../_metronic/_partials/dropdowns";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

export function DashboardCard({ tablesClasses }) {
  const [dashData, setDashData] = useState({});
  const { currentState } = useSelector(
    (state) => ({ currentState: state.dashboard }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    // server call by queryParams
    dispatch(actions.fetchDashboardData());
    // dispatch(actions.getAllProducts());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { mainDashboard } = currentState;

  const goToPaymentDetails = (id) => {
    history.push(`/payments/${id}/details`);
  };
  const goToProviderDetails = (id) => {
    history.push(`/providers/${id}/details`);
  };
  const goToPatientDetails = (id) => {
    history.push(`/patients/${id}/details`);
  };
  const goToAppointmentDetails = (id) => {
    history.push(`/appointments/${id}/details`);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-3">
          <div
            className="card card-custom bgi-no-repeat card-stretch gutter-b"
            style={{
              backgroundPosition: "right top",
              backgroundSize: "30% auto",
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/svg/shapes/abstract-1.svg)"
              )}`,
            }}
          >
            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-info">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group-chat.svg"
                  )}
                />
              </span>
              <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                {mainDashboard?.totalAppointmentsCount}
              </span>
              <span className="font-weight-bold text-muted font-size-sm">
                Total Appointments
              </span>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="card card-custom bg-info card-stretch gutter-b">
            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-white">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group-chat.svg"
                  )}
                />
              </span>
              <span className="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 text-hover-primary d-block">
                {mainDashboard?.totalUsersCount}
              </span>
              <span className="font-weight-bold text-white font-size-sm">
                Total Patients
              </span>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="card card-custom bg-primary card-stretch gutter-b">
            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-white">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group-chat.svg"
                  )}
                />
              </span>
              <span className="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 text-hover-primary d-block">
                {mainDashboard?.totalProvidersCount}
              </span>
              <span className="font-weight-bold text-white font-size-sm">
                Total Providers
              </span>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="card card-custom bg-success card-stretch gutter-b">
            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-white">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group-chat.svg"
                  )}
                />
              </span>
              <span className="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 text-hover-primary d-block">
                -
              </span>
              <span className="font-weight-bold text-white font-size-sm">
                Total Payments
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className={tablesClasses}>
          <div className={`card card-custom card-stretch gutter-b`}>
            {/* Head */}
            <div className="card-header border-0">
              <h3 className="card-title font-weight-bolder text-dark">
                Payments
              </h3>
              <div className="card-toolbar">
                <div id="dropdown-toggle-top2" variant="transparent">
                  <NavLink to="/payments">
                    <span className="btn btn-light-primary btn-sm font-weight-bolder ">
                      See all
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
            {/* Body */}
            <div className="card-body pt-2">
              {mainDashboard?.payments?.map((res) => {
                let userPicStyle = toAbsoluteUrl(
                  "/media/svg/avatars/015-boy-6.svg"
                );
                // let userPicStyle=null;
                if (res?.user?.profile_picture?.url) {
                  userPicStyle = res?.user?.profile_picture?.formats?.thumbnail
                    ?.url
                    ? res?.user?.profile_picture?.formats?.thumbnail?.url
                    : res?.user?.profile_picture?.url;
                }

                return (
                  <div
                    className="d-flex align-items-center mb-10"
                    key={res.id}
                    onClick={() => goToPaymentDetails(res.id)}
                  >
                    <div className="symbol symbol-40 symbol-light-success mr-5">
                      <span
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${userPicStyle})`,
                        }}
                      >
                        {/* <SVG
            className="h-75 align-self-end"
            src={userPic}
          ></SVG> */}
                      </span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                      <a
                        href="#"
                        className="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        {res?.user?.first_name} {res?.user?.last_name}
                      </a>
                      <span className="text-muted">{res?.status}</span>
                    </div>

                    <div className="text-dark text-hover-primary mb-1 font-size-lg font-weight-bold">
                      {(res?.currency).toUpperCase()} {res?.amount}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={tablesClasses}>
          <div className={`card card-custom card-stretch gutter-b`}>
            {/* Head */}
            <div className="card-header border-0">
              <h3 className="card-title font-weight-bolder text-dark">
                Providers
              </h3>
              <div className="card-toolbar">
                <div id="dropdown-toggle-top2" variant="transparent">
                  <NavLink to="/providers">
                    <span className="btn btn-light-primary btn-sm font-weight-bolder ">
                      See all
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
            {/* Body */}
            <div className="card-body pt-2">
              {mainDashboard?.providers?.map((res) => {
                let userPicStyle = toAbsoluteUrl(
                  "/media/svg/avatars/015-boy-6.svg"
                );
                // let userPicStyle=null;
                if (res?.user?.profile_picture?.url) {
                  userPicStyle = res?.user?.profile_picture?.formats?.thumbnail
                    ?.url
                    ? res?.user?.profile_picture?.formats?.thumbnail?.url
                    : res?.user?.profile_picture?.url;
                }

                return (
                  <div
                    className="d-flex align-items-center mb-10"
                    key={res.id}
                    onClick={() => goToProviderDetails(res.id)}
                  >
                    <div className="symbol symbol-40 symbol-light-success mr-5">
                      <span
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${userPicStyle})`,
                        }}
                      >
                        {/* <SVG
            className="h-75 align-self-end"
            src={userPic}
          ></SVG> */}
                      </span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                      <a
                        href="#"
                        className="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        {res?.title} {res?.user?.first_name}{" "}
                        {res?.user?.last_name}
                      </a>
                      <span className="text-muted">{res?.specialty?.name}</span>
                    </div>
                    {res?.rate && (
                      <div className="text-dark text-hover-primary mb-1 font-size-lg font-weight-bold">
                        {res?.currency?.toUpperCase()} {res?.rate}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className={tablesClasses}>
          <div className={`card card-custom card-stretch gutter-b`}>
            {/* Head */}
            <div className="card-header border-0">
              <h3 className="card-title font-weight-bolder text-dark">
                Patients
              </h3>
              <div className="card-toolbar">
                <div id="dropdown-toggle-top2" variant="transparent">
                  <NavLink to="/patients">
                    <span className="btn btn-light-primary btn-sm font-weight-bolder ">
                      See all
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
            {/* Body */}
            <div className="card-body pt-2">
              {mainDashboard?.patients?.map((res) => {
                let userPicStyle = toAbsoluteUrl(
                  "/media/svg/avatars/015-boy-6.svg"
                );
                // let userPicStyle=null;
                if (res?.profile_picture?.url) {
                  userPicStyle = res?.profile_picture?.formats?.thumbnail?.url
                    ? res?.profile_picture?.formats?.thumbnail?.url
                    : res?.user?.profile_picture?.url;
                }

                return (
                  <div
                    className="d-flex align-items-center mb-10"
                    key={res.id}
                    onClick={() => goToPatientDetails(res.id)}
                  >
                    <div className="symbol symbol-40 symbol-light-success mr-5">
                      <span
                        className="symbol-label"
                        style={{ backgroundImage: `url(${userPicStyle})` }}
                      >
                        {/* <SVG
            className="h-75 align-self-end"
            src={userPic}
          ></SVG> */}
                      </span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                      <a
                        href="#"
                        className="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        {res?.first_name} {res?.last_name}
                      </a>
                      <span className="text-muted">{res?.email}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className={tablesClasses}>
          <div className={`card card-custom card-stretch gutter-b`}>
            {/* Head */}
            <div className="card-header border-0">
              <h3 className="card-title font-weight-bolder text-dark">
                Appointments
              </h3>
              <div className="card-toolbar">
                <div id="dropdown-toggle-top2" variant="transparent">
                  <NavLink to="/appointments">
                    <span className="btn btn-light-primary btn-sm font-weight-bolder ">
                      See all
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
            {/* Body */}
            <div className="card-body pt-2">
              {mainDashboard?.appointments?.map((res) => {
                let userPicStyle = toAbsoluteUrl(
                  "/media/svg/avatars/015-boy-6.svg"
                );
                // let userPicStyle=null;
                if (res?.user?.profile_picture?.url) {
                  userPicStyle = res?.user?.profile_picture?.formats?.thumbnail
                    ?.url
                    ? res?.user?.profile_picture?.formats?.thumbnail?.url
                    : res?.user?.profile_picture?.url;
                }

                let appType = {
                  Video: "/media/svg/icons/Devices/Video-camera.svg",
                  "In-Person": "/media/svg/icons/Home/Building.svg",
                  Call: "/media/svg/icons/Communication/Active-call.svg",
                  Chat: "/media/svg/icons/Communication/Group-chat.svg",
                };

                let appTypeStyle =
                  res?.type == "system" ? "success" : "primary";

                return (
                  <div
                    className="d-flex align-items-center mb-10"
                    key={res.id}
                    onClick={() => goToAppointmentDetails(res.id)}
                  >
                    <div className="symbol symbol-40 symbol-light-success mr-5">
                      <span
                        className="symbol-label"
                        style={{ backgroundImage: `url(${userPicStyle})` }}
                      >
                        {/* <SVG
            className="h-75 align-self-end"
            src={userPic}
          ></SVG> */}
                      </span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                      <a
                        href="#"
                        className="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        {res?.user?.first_name} {res?.user?.last_name}
                      </a>
                      <span className="text-muted">
                        {res?.session_start_time}
                      </span>
                    </div>
                    <div
                      className={`symbol symbol-40 symbol-light-${appTypeStyle} mr-5`}
                    >
                      <span className="symbol-label">
                        <span className={`svg-icon svg-icon-${appTypeStyle}`}>
                          <SVG
                            className="h-75 align-self-end  "
                            src={toAbsoluteUrl(
                              appType[res?.appointment_type?.name]
                            )}
                          ></SVG>
                        </span>
                      </span>
                    </div>
                    {/* <div    className="text-dark text-hover-primary mb-1 font-size-lg font-weight-bold">
      {res?.appointment_type?.name}
          </div> */}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
