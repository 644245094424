import React, { useState, useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as actions from "../../_redux/providersActions";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import * as uiHelpers from "./ProviderPatientsUIHelpers";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "../column-formatters";
// import * as providers from "../../Providers";
import {
  ProviderPatientsUIProvider,
  useProviderPatientsUIContext,
} from "./ProviderPatientsUIContext";
import { useProvidersUIContext } from "../../ProvidersUIContext";

function ProviderPatients({ history }) {
  const ProviderPatientsUIEvents = {
    openDetailProviderPatientsPage: (id) => {
      history.push(`/providers/${id}/patients`);
    },
  };

  const providerPatientsUIContext = useProviderPatientsUIContext();

  const providerPatientsUIProps = useMemo(() => {
    return {
      ids: providerPatientsUIContext.ids,
      setIds: providerPatientsUIContext.setIds,
      queryParams: providerPatientsUIContext.queryParams,
      setQueryParams: providerPatientsUIContext.setQueryParams,
      openDetailProviderAppointmentTransactionPage:
        providerPatientsUIContext.openDetailProviderAppointmentTransactionPage,
    };
  }, [providerPatientsUIContext]);

  // Fields
  const [loading, setloading] = useState(false);
  const [pic, setPic] = useState("");
  const dispatch = useDispatch();
  const currentState = useSelector(
    (state) => state.providers.patients,
    shallowEqual
  );
  const provider = useSelector(
    (state) => state.providers.provider,
    shallowEqual
  );

  useEffect(() => {
    // clear selections list
    providerPatientsUIProps.setIds([]);

    dispatch(
      actions.fetchProviderPatients(provider.id, providerPatientsUIProps)
    );
  }, [providerPatientsUIProps.queryParams, dispatch]);
  // Methods

  const { totalCount, entities, listLoading } = currentState;

  // Table columns
  const columns = [
    {
      dataField: "user.username",
      text: "Patient",
      sort: true,
      sortCaret: sortCaret,
    },

    {
      dataField: "view_appointments",
      text: "Appointments",
      sort: true,
      formatter: columnFormatters.PatientPermissionsStatusColumnFormatter,
      sortCaret: sortCaret,
    },
    {
      dataField: "view_medications",
      text: "Medications",
      sort: true,
      formatter: columnFormatters.PatientPermissionsStatusColumnFormatter,
      sortCaret: sortCaret,
    },
    {
      dataField: "view_labs",
      text: "Labs",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.PatientPermissionsStatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "view_health_conditions",
      text: "Conditions",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.PatientPermissionsStatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: columnFormatters.ActionsColumnFormatter,
    //   formatExtraData: {
    //     openDetailImmunizationPage: providerPatientsUIProps.openDetailImmunizationPage,
    //     openEditProductPage: providerPatientsUIProps.openEditProductPage,
    //     openDeleteProductDialog: providerPatientsUIProps.openDeleteProductDialog,
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: providerPatientsUIProps.queryParams.pageSize,
    page: providerPatientsUIProps.queryParams.pageNumber,
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // providerPatientsUIProps.openDetailProviderPage(row?.id)
    },
  };

  return (
    <>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">Patients</h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            List of provider's patients
          </span>
        </div>
      </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  providerPatientsUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: providerPatientsUIProps.ids,
                  setIds: providerPatientsUIProps.setIds,
                })}
                rowEvents={rowEvents}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

// export default connect(null, providers.actions)(ProviderPersonaInformation);
export default ProviderPatients;
