import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"; 
// import * as patients from "../../Patients";

function PatientPaymentInformation(props) {
  // Fields
  const [loading, setloading] = useState(false);
  const [pic, setPic] = useState("");
  const dispatch = useDispatch();
  const patient = useSelector((state) => state.patients.patient, shallowEqual);

  useEffect(() => {
  
    if (patient?.user?.profile_picture) {
      setPic(patient?.user?.profile_picture?.url);
    }
  }, [patient]);
  // Methods

  // UI Helpers
  const initialValues = { 
    rate: `GHS ${patient?.rate}`,
    pay_patient: patient?.pay_patient,
    pay_number: patient?.pay_number, 
  };
  const Schema = Yup.object().shape({
    pic: Yup.string(),
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    companyName: Yup.string(),
    phone: Yup.string().required("Phone is required"),
    email: Yup.string()
      .email("Wrong email format")
      .required("Email is required"),
    website: Yup.string(),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
    //   saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  const getUserPic = () => {
    if (!pic) {
      return "none";
    }

    return `url(${pic})`;
  };
  const removePic = () => {
    setPic("");
  };
  return (
    <form
      className="card card-custom card-stretch"
      onSubmit={formik.handleSubmit}
    >
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Payment Information
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Information set as a patient
          </span>
        </div>
        {/* <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          <Link
            to="/user-profile/profile-overview"
            className="btn btn-secondary"
          >
            Cancel
          </Link>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        {/* begin::Body */}
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">General Info</h5>
            </div>
          </div>
         
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Rate
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text" 
                disabled
                className={`form-control form-control-lg form-control-solid`}
                name="rate"
                {...formik.getFieldProps("rate")}
              /> 
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Payment Patient
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text" 
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "pay_patient"
                )}`}
                disabled
                name="pay_patient"
                {...formik.getFieldProps("pay_patient")}
              />
             
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
            Account Number
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text" 
                disabled
                className={`form-control form-control-lg form-control-solid`}
                name="pay_number"
                {...formik.getFieldProps("pay_number")}
              /> 
            </div>
          </div>
         
        </div>
        {/* end::Body */}
      </div>
      {/* end::Form */}
    </form>
  );
}

// export default connect(null, patients.actions)(PatientPersonaInformation);
export default PatientPaymentInformation
