import axios from "axios";
import { baseURL } from "../../../../config";
import { buildFilterString } from "../../../utils/utils";

// export const PRODUCTS_URL = "api/products";
export const PRODUCTS_URL = `${baseURL}/control/appointments`;

// CREATE =>  POST: add a new product to the server
export function createProduct(product) {
  return axios.post(PRODUCTS_URL, { product });
}

// READ
export function getAllProducts() {
  return axios.get(PRODUCTS_URL);
}

export function getAppointmentById(productId) {
  return axios.get(`${PRODUCTS_URL}/${productId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findAppointments(queryParams) {
  let startNumber =
    queryParams.pageNumber == 1
      ? 0
      : queryParams.pageNumber * queryParams.pageSize - 9;
  let filterString = buildFilterString(queryParams.filter);
  return axios.get(
    `${PRODUCTS_URL}?_start=${startNumber}&_limit=${queryParams.pageSize}&_sort=created_at:DESC${filterString}`
  );
  //   return axios.get(`${PRODUCTS_URL}`);
}

// UPDATE => PUT: update the procuct on the server
export function updateProduct(product) {
  return axios.put(`${PRODUCTS_URL}/${product.id}`, { product });
}

// UPDATE Status
export function updateStatusForProducts(ids, status) {
  return axios.post(`${PRODUCTS_URL}/updateStatusForProducts`, {
    ids,
    status,
  });
}

// DELETE => delete the product from the server
export function deleteProduct(productId) {
  return axios.delete(`${PRODUCTS_URL}/${productId}`);
}

// DELETE Products by ids
export function deleteProducts(ids) {
  return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
}
