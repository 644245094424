import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"; 
// import * as patients from "../../Patients";

function PatientDocuments(props) {
  // Fields
  const [loading, setloading] = useState(false);
  const [pic, setPic] = useState("");
  const dispatch = useDispatch();
  const patient = useSelector((state) => state.patients.patient, shallowEqual);

  useEffect(() => {
  
    if (patient?.user?.profile_picture) {
      setPic(patient?.user?.profile_picture?.url);
    }
  }, [patient]);
  // Methods

  // UI Helpers
  const initialValues = { 
    official_id: patient?.official_id,
    supporting_documents: patient?.supporting_documents 
  };
  const Schema = Yup.object().shape({
    pic: Yup.string(),
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    companyName: Yup.string(),
    phone: Yup.string().required("Phone is required"),
    email: Yup.string()
      .email("Wrong email format")
      .required("Email is required"),
    website: Yup.string(),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
    //   saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  const getUserPic = () => {
    if (!pic) {
      return "none";
    }

    return `url(${pic})`;
  };
  const removePic = () => {
    setPic("");
  };
  return (
    <form
      className="card card-custom card-stretch"
      onSubmit={formik.handleSubmit}
    >
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Patient Documents
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Information set as a patient
          </span>
        </div>
        {/* <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          <Link
            to="/user-profile/profile-overview"
            className="btn btn-secondary"
          >
            Cancel
          </Link>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        {/* begin::Body */}
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">Official ID</h5>
            </div>
          </div>
         
          <div className=" row pt-2">
    
    
{patient?.official_id &&  ( <div className=" col-lg-4 col-xxl-4">
<div className="bgi-no-repeat bgi-size-cover rounded min-h-180px w-100" style={{backgroundImage: `url('${patient?.official_id?.url}')`}}></div>
</div>
) }
</div>
        
         
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mt-10 mb-6">Supporting Documents</h5>
            </div>
          </div>

          <div className="row pt-2">
       {patient?.supporting_documents?.map(res =>{
        return ( <div key={res?.id} className="col-lg-4 col-xxl-4 mb-6">
        <div className="bgi-no-repeat bgi-size-cover rounded min-h-180px w-100" style={{backgroundImage: `url('${res?.formats?.medium?.url?res?.formats?.medium?.url:res?.url}')`}}></div>
        </div>
        )
        })}
        </div>
         
          
         
        </div>
        {/* end::Body */}
      </div>
      {/* end::Form */}
    </form>
  );
}

// export default connect(null, patients.actions)(PatientPersonaInformation);
export default PatientDocuments
