import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { ImmunizationDetails } from "./modules/Immunizations/immunization-details/ImmunizationDetails";
import { ImmunizationEdit } from "./modules/Immunizations/immunization-edit/ImmunizationEdit";
import { ProviderDetails } from "./modules/Providers/provider-details/ProviderDetails";
import { PatientDetails } from "./modules/Patients/patient-details/PatientDetails";
import { LabDetails } from "./modules/Labs/labs-details/LabDetails";
import { MedicationDetails } from "./modules/Medications/medications-details/MedicationDetails";
import { PaymentDetails } from "./modules/Payments/payments-details/PaymentDetails";
import { AppointmentDetails } from "./modules/Appointments/appointments-details/AppointmentDetails";
import { ProductDetails } from "./modules/Products/products-details/ProductDetails";
import { OrganisationDetails } from "./modules/Organisations/organisations-details/OrganisationDetails";

// import { ImmunizationsPage } from "./modules/Immunizations/ImmunizationsPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const ImmunizationsPage = lazy(() =>
  import("./modules/Immunizations/ImmunizationsPage")
);
const LabsPage = lazy(() => import("./modules/Labs/LabsPage"));
const MedicationsPage = lazy(() =>
  import("./modules/Medications/MedicationsPage")
);
const ProductsPage = lazy(() => import("./modules/Products/ProductsPage"));

const PaymentsPage = lazy(() => import("./modules/Payments/PaymentsPage"));
const AppointmentsPage = lazy(() =>
  import("./modules/Appointments/AppointmentsPage")
);
const ProvidersPage = lazy(() => import("./modules/Providers/ProvidersPage"));
const PatientsPage = lazy(() => import("./modules/Patients/PatientsPage"));
const OrganisationsPage = lazy(() =>
  import("./modules/Organisations/OrganisationsPage")
);
const OrgPatientsPage = lazy(() =>
  import("./modules/OrgPatients/OrgPatientsPage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <ContentRoute
          path="/immunizations/:id/details"
          component={ImmunizationDetails}
        />
        <ContentRoute
          path="/immunizations/:id/edit"
          component={ImmunizationEdit}
        />
        <Route path="/immunizations" component={ImmunizationsPage} />

        <ContentRoute path="/labs/:id/details" component={LabDetails} />

        <Route path="/labs" component={LabsPage} />

        <ContentRoute
          path="/medications/:id/details"
          component={MedicationDetails}
        />

        <Route path="/medications" component={MedicationsPage} />

        <ContentRoute path="/products/:id/details" component={ProductDetails} />

        <Route path="/products" component={ProductsPage} />

        <ContentRoute path="/payments/:id" component={PaymentDetails} />

        <Route path="/payments" component={PaymentsPage} />

        <ContentRoute
          path="/appointments/:id/details"
          component={AppointmentDetails}
        />

        <Route path="/appointments" component={AppointmentsPage} />

        <ContentRoute path="/providers/:id" component={ProviderDetails} />

        <Route path="/providers" component={ProvidersPage} />

        <ContentRoute
          path="/org-patients/requests"
          component={OrgPatientsPage}
        />

        <ContentRoute path="/patients/:id" component={PatientDetails} />

        <Route path="/patients" component={PatientsPage} />

        <ContentRoute
          path="/organisations/:id"
          component={OrganisationDetails}
        />

        <Route path="/organisations" component={OrganisationsPage} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
