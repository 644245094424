import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
// import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { immunizationsSlice } from "../app/modules/Immunizations/_redux/immunizationsSlice";
import { providersSlice } from "../app/modules/Providers/_redux/ProductsSlice";
import { patientsSlice } from "../app/modules/Patients/_redux/PatientsSlice";
import { organisationsSlice } from "../app/modules/Organisations/_redux/organisationsSlice";
import { orgPatientsSlice } from "../app/modules/OrgPatients/_redux/PatientsSlice";
import { dashboardSlice } from "../app/modules/Dashboard/_redux/DashboardSlice";
import { labsSlice } from "../app/modules/Labs/_redux/labsSlice";
import { medicationsSlice } from "../app/modules/Medications/_redux/medicationsSlice";
import { paymentsSlice } from "../app/modules/Payments/_redux/paymentsSlice";
import { appointmentsSlice } from "../app/modules/Appointments/_redux/appointmentsSlice";
import { productsSlice } from "../app/modules/Products/_redux/productsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  immunizations: immunizationsSlice.reducer,
  providers: providersSlice.reducer,
  patients: patientsSlice.reducer,
  orgpatients: orgPatientsSlice.reducer,
  dashboard: dashboardSlice.reducer,
  labs: labsSlice.reducer,
  medications: medicationsSlice.reducer,
  payments: paymentsSlice.reducer,
  appointments: appointmentsSlice.reducer,
  products: productsSlice.reducer,
  organisations: organisationsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
