/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  DropdownCustomToggler,
  DropdownMenu4,
} from "../../../../_metronic/_partials/dropdowns";

import ReactJson from "react-json-view";

import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import {
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useDispatch } from "react-redux";
import * as actions from "../_redux/productsActions";

export function ProductDetailList({ className, productData }) {
  const typeClass = productData?.type == "system" ? "success" : "primary";
  const [isJSONValid, setIsJSONValid] = useState(false);
  const [payloadJSON, setPayloadJSON] = useState(null);

  const dispatch = useDispatch();

  const handleJSONChange = (data) => {
    console.log(data.error);
    console.log(JSON.parse(data.json));
    console.log(JSON.stringify(data.json));
    if (!data.error) {
      setPayloadJSON(data.json);
      setIsJSONValid(true);
      return;
    }
    setIsJSONValid(false);
  };
  const savePayloadJSON = () => {
    console.log(JSON.stringify(payloadJSON));
    dispatch(
      actions.updateProduct({ id: productData?.id, payload: payloadJSON })
    ).then(() => {
      // setPayloadJSON(null);
      // setIsJSONValid(false);
      window.location.reload();
    });
  };

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            General Information
          </h3>
          {/* <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                variant="transparent"
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
               >
                <i className="ki ki-bold-more-hor" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu4 />
              </Dropdown.Menu>
            </Dropdown>            
          </div> */}
        </div>

        {/* Body */}
        <div className="row card-body pt-2">
          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-productel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Flag.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Product Name
                </a>
                <span className="text-muted">{productData?.name}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-productel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                >
                  UUID
                </a>
                <span className="text-muted">{productData?.uuid}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-productel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                >
                  Date Created
                </a>
                <span className="text-muted">{productData?.created_at}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-productel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Last Updated
                </a>
                <span className="text-muted">{productData?.updated_at}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-xxl-3">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-productel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Status
                </a>
                <span className="text-muted">{productData?.status}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xxl-6">
            <div className="d-flex align-items-center mb-10">
              <div
                className={`symbol symbol-40 symbol-light-${typeClass} mr-5`}
              >
                <span className="symbol-productel">
                  <span
                    className={`svg-icon svg-icon-lg svg-icon-${typeClass}`}
                  >
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Description
                </a>
                <span className="text-muted">{productData?.description}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xxl-6">
            <div className="d-flex align-items-center mb-10">
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  Dynamic Form
                </a>
                <ReactJson
                  src={productData?.payload}
                  displayDataTypes={false}
                  onEdit={false}
                  onAdd={false}
                  onDelete={false}
                />

                {/* <span className="text-muted">{productData?.description}</span> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xxl-6">
            <div className="d-flex align-items-center mb-10">
              <div className="d-flex flex-column font-weight-bold">
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display: "flex",
                    marginBottom: 10,
                  }}
                >
                  <a
                    href="#"
                    className="text-dark text-hover-primary mb-1 font-size-lg"
                  >
                    Dynamic Form
                  </a>

                  {isJSONValid && (
                    <button
                      type="button"
                      onClick={savePayloadJSON}
                      className="btn btn-primary"
                    >
                      {/* <i className="fa fa-arrow-left"></i> */}
                      Save
                    </button>
                  )}
                </div>

                {/* <ReactJson
                  src={productData?.payload}
                  displayDataTypes={false}
                  onEdit={true}
                  onAdd={true}
                  onDelete={true}
                /> */}

                <JSONInput
                  id="a_unique_id"
                  placeholder={productData?.payload}
                  // colors={darktheme}
                  locale={locale}
                  // height="550px"
                  viewOnly={false}
                  onChange={handleJSONChange}
                />

                {/* <span className="text-muted">{productData?.description}</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
