import React from "react";
import { AdvanceTablesWidget7, ListsWidget10, ListsWidget14 } from '../../../../_metronic/_partials/widgets';
import { DashboardCard } from "../../Dashboard/DashboardCard";
// import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function PatientProfileOverview() {
  // return (
  //   <div className="row">
  //     <div className="col-lg-6">
  //       <ListsWidget14 className="card-stretch gutter-b"></ListsWidget14>
  //     </div>
  //     <div className="col-lg-6">
  //       <ListsWidget10 className="card-stretch gutter-b"></ListsWidget10>
  //     </div>
  //     <div className="col-lg-12">
  //       <AdvanceTablesWidget7 className="card-stretch gutter-b"></AdvanceTablesWidget7>
  //     </div>
  //   </div>
  // );

  return (<DashboardCard tablesClasses={"col-lg-6 col-xxl-6 order-1 order-xxl-1"} />)
}
