import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/patientsActions";
import { useSubheader } from "../../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import {
  DropdownCustomToggler,
  DropdownMenu4,
} from "../../../../_metronic/_partials/dropdowns";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { ListsWidget1 } from "../../../../_metronic/_partials/widgets";
import { PatientProfileOverview } from "./PatientProfileOverview";
import PatientPersonalInformation from "./PatientPersonalInformation";
import PatientSpecialtyInformation from "./PatientSpecialtyInformation";
import PatientDocuments from "./PatientDocuments";
import PatientPaymentInformation from "./PatientPaymentInformation";
import PatientPaymentTransactions from "./patient-payments/PatientPaymentTransactions";
import { PatientPaymentTransactionsUIProvider } from "./patient-payments/PatientPaymentTransactionsUIContext";
import { PatientAppointmentsUIProvider } from "./patient-appointments/PatientAppointmentsUIContext";
import PatientAppointments from "./patient-appointments/PatientAppointments";
import { PatientMedicationsUIProvider } from "./patient-medications/PatientMedicationsUIContext";
import PatientMedications from "./patient-medications/PatientMedications";
import { PatientLabsUIProvider } from "./patient-labs/PatientLabsUIContext";
import PatientLabs from "./patient-labs/PatientLabs";
import { PatientImmunizationsUIProvider } from "./patient-immunizations/PatientImmunizationsUIContext";
import PatientImmunizations from "./patient-immunizations/PatientImmunizations";
// import { ImmunizationDetailList } from "./ImmunizationDetailList";

export const PatientDetails = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Patient Details");

  const dispatch = useDispatch();

  const { actionsLoading, patient } = useSelector(
    (state) => ({
      actionsLoading: state.patients.actionsLoading,
      patient: state.patients.patient,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchPatient(id));
  }, [id, dispatch]);

  const btnRef = useRef();
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToPatientsList = () => {
    history.push(`/patients`);
  };

  const PatientPaymentTransactionsUIEvents = {
    openDetailPatientPaymentTransactionsPage: (id) => {
      history.push(`/patients/${id}/payment-transactions`);
    },
  };
  const PatientAppointmentsUIEvents = {
    openDetailPatientAppointmentsPage: (id) => {
      history.push(`/patients/${id}/appointments`);
    },
  };
  const PatientMedicationsUIEvents = {
    openDetailPatientMedicationsPage: (id) => {
      history.push(`/patients/${id}/medications`);
    },
  };
  const PatientLabsUIEvents = {
    openDetailPatientLabsPage: (id) => {
      history.push(`/patients/${id}/labs`);
    },
  };
  const PatientImmunizationsUIEvents = {
    openDetailPatientImmunizationsPage: (id) => {
      history.push(`/patients/${id}/immunizations`);
    },
  };

  return (
    <Card>
      <CardHeader title={"Patient Details"}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToPatientsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {/* {`  `}
          <button className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveProductClick}
          >
            Save
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="d-flex flex-row">
          <>
            {patient && (
              <div
                className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
                id="kt_profile_aside"
              >
                <div className="card card-custom card-stretch">
                  {/* begin::Body */}
                  <div className="card-body pt-4">
                    {/* begin::Toolbar */}
                    <div className="d-flex justify-content-end">
                      <Dropdown className="dropdown dropdown-inline" alignRight>
                        <Dropdown.Toggle
                          className="btn btn-clean btn-hover-light-primary btn-sm btn-icon cursor-pointer"
                          variant="transparent"
                          id="dropdown-toggle-top-user-profile"
                          as={DropdownCustomToggler}
                        >
                          <i className="ki ki-bold-more-hor"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                          <DropdownMenu4></DropdownMenu4>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {/* end::Toolbar */}
                    {/* begin::User */}
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                        <div
                          className="symbol-label"
                          style={{
                            backgroundImage: `url(${
                              patient?.profile_picture?.formats?.medium?.url
                                ? patient?.profile_picture?.formats?.medium?.url
                                : patient?.profile_picture?.url
                            })`,
                          }}
                        ></div>
                        {/* style="background-i
                  mage:url('/metronic/theme/html/demo1/dist/assets/media/users/300_21.jpg')" */}
                        <i className="symbol-badge bg-success"></i>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                        >
                          {patient?.first_name} {patient?.last_name}
                        </a>
                        <div className="text-muted">
                          {patient?.specialty?.name}
                        </div>
                        <div className="mt-2">
                          <a
                            href="#"
                            className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                          >
                            Chat
                          </a>
                          <a
                            href="#"
                            className="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
                          >
                            Status
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold mr-2">Email:</span>
                        <span className="text-muted text-hover-primary">
                          {patient?.email}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold mr-2">Phone:</span>
                        <span className="text-muted">{patient?.tel}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="font-weight-bold mr-2">Location:</span>
                        {/* <span className="text-muted">{patient?.address.state} {patient?.address.country}</span> */}
                      </div>
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                      {/* <div className="navi-item mb-2">
                        <NavLink
                          to={`/patients/${patient?.id}/profile-overview`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Design/Layers.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Profile Overview
                          </span>
                        </NavLink>
                      </div> */}
                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/patients/${patient?.id}/personal-information`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/User.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Personal Information
                          </span>
                        </NavLink>
                      </div>

                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/patients/${patient?.id}/appointments`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Shield-user.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Appointments
                          </span>
                        </NavLink>
                      </div>

                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/patients/${patient?.id}/medications`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Shield-user.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Medications
                          </span>
                        </NavLink>
                      </div>

                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/patients/${patient?.id}/labs`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Shield-user.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">Labs</span>
                        </NavLink>
                      </div>

                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/patients/${patient?.id}/immunizations`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Shield-user.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Immunizations
                          </span>
                        </NavLink>
                      </div>

                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/patients/${patient?.id}/payment-transactions`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Shield-user.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Payments
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    {/* end::Nav */}
                  </div>
                  {/* end::Body */}
                </div>
              </div>
            )}
          </>
          <div className="flex-row-fluid ml-lg-8">
            <Switch>
              <Redirect
                from={`/patients/${patient?.id}`}
                exact={true}
                to={`/patients/${patient?.id}/personal-information`}
              />
              <Route
                path={`/patients/${patient?.id}/profile-overview`}
                component={PatientProfileOverview}
              />
              <Route
                path={`/patients/${patient?.id}/personal-information`}
                component={PatientPersonalInformation}
              />
              <Route
                path={`/patients/${patient?.id}/specialty-information`}
                component={PatientSpecialtyInformation}
              />
              <Route
                path={`/patients/${patient?.id}/documents`}
                component={PatientDocuments}
              />
              <Route
                path={`/patients/${patient?.id}/payment-information`}
                component={PatientPaymentInformation}
              />

              <Route path={`/patients/${patient?.id}/appointments`}>
                <PatientAppointmentsUIProvider
                  PatientAppointmentsUIEvents={PatientAppointmentsUIEvents}
                >
                  <PatientAppointments />
                </PatientAppointmentsUIProvider>
              </Route>

              <Route
                path={`/patients/${patient?.id}/payment-transactions`}
                // component={PatientPaymentTransactions}
              >
                <PatientPaymentTransactionsUIProvider
                  PatientPaymentTransactionsUIEvents={
                    PatientPaymentTransactionsUIEvents
                  }
                >
                  <PatientPaymentTransactions />
                </PatientPaymentTransactionsUIProvider>
              </Route>

              <Route path={`/patients/${patient?.id}/medications`}>
                <PatientMedicationsUIProvider
                  PatientMedicationsUIEvents={PatientMedicationsUIEvents}
                >
                  <PatientMedications />
                </PatientMedicationsUIProvider>
              </Route>
              <Route path={`/patients/${patient?.id}/labs`}>
                <PatientLabsUIProvider
                  PatientLabsUIEvents={PatientLabsUIEvents}
                >
                  <PatientLabs />
                </PatientLabsUIProvider>
              </Route>

              <Route path={`/patients/${patient?.id}/immunizations`}>
                <PatientImmunizationsUIProvider
                  PatientImmunizationsUIEvents={PatientImmunizationsUIEvents}
                >
                  <PatientImmunizations />
                </PatientImmunizationsUIProvider>
              </Route>

              <Route path={`/patients/${patient?.id}/payments`}>
                <PatientPaymentTransactionsUIProvider
                  PatientPaymentTransactionsUIEvents={
                    PatientPaymentTransactionsUIEvents
                  }
                >
                  <PatientPaymentTransactions />
                </PatientPaymentTransactionsUIProvider>
              </Route>

              {/* <Route
            path="/user-profile/profile-overview"
            component={PatientProfileOverview}
          />
          <Route
            path="/user-profile/account-information"
            component={AccountInformation}
          />
          <Route
            path="/user-profile/change-password"
            component={ChangePassword}
          />
          <Route
            path="/user-profile/email-settings"
            component={EmailSettings}
          />
          <Route
            path="/user-profile/personal-information"
            component={PersonaInformation}
          /> */}
            </Switch>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
