import React from "react";
import {
  Dashboard
} from "../../_metronic/_partials";

import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers"
import MainDashboardPage from "../modules/Dashboard/DashboardPage";

export function DashboardPage() {
  // return <Dashboard />;
  return (  <MainDashboardPage/>  );
}
